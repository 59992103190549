button[mat-raised-button] {
    border-radius: 0;
    box-shadow: none !important;
    
    &.full {
        width: 100%;
    }
    
    &.dark {
        background-color: #152935;
        --background-hover: #152935;
        --border-radius: 0px;
        color: #fff;
    }
    
    &.back {
        border: 1px solid #979797;
    }
}

ion-button {
    &.add-entry {
        --background: #fff;
        --background-hover: #fff;
        --background-activated: #fff;
        border: 1px solid #979797;
        --color: #152935;
        margin-top: 15px;
    }
    
    &.link {
        --background-focused: #fff;
        --background-activated: #fff;
        --background-hover: #fff;
        --background: #fff;
        --color: #3d70b2;
        --color-activated: #3d70b2;
        --ripple-color: #fff;
        cursor: pointer;
        text-decoration: underline;
        font-weight: bold;
        letter-spacing: 0;
        width: auto;
    }
    
    width: 100%;
    --background: #152935;
    --background-hover: #152935;
    --border-radius: 0px;
}
