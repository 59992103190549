$break-mobile: 600px;
$break-tablet: 1100px;
$break-desktop: 1280px;
$break-desktop-xl: 1800px;

@mixin size($media) {
  @if $media == mobile {
    @media only screen and (max-width: #{$break-mobile - 1}) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $break-mobile) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $break-tablet) {
      @content;
    }
  } @else if $media == desktop-xl {
    @media only screen and (min-width: $break-desktop-xl) {
      @content;
    }
  }
}


ion-modal {
  .modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100% !important;
    height: 100% !important;
  }
}

ion-button {
  --box-shadow: none;
}

ion-header {
  ion-img {
    width: 130px;
    float: left;
    margin: 20px 30px;
    @include size(tablet) {
      margin: 6px 6px;
    }
    @include size(mobile) {
      margin: 6px 6px;
    }
  }
}

ion-tab-button {
  max-width: 100%;
  color: #000;

  &.tab-selected {
    border-bottom: 4px black solid;
    @include size(mobile) {
      border-bottom: 2px black solid;
    }
  }
}


.center-fab {
  left: 50%;
  transform: translateX(-50%);
  margin-inline-start: 0;
  bottom: 4vh;
  @include size(tablet) {
    bottom: 2vh;
  }
  @include size(mobile) {
    bottom: 1vh;
  }
}

