ion-input, ion-datetime {
  --padding-start: 0;
  --background: #f4f7fb !important;
  margin-top: 9px !important;
  border-bottom: #5A6872 solid 1px;
  padding-left: 7px;
  background-color: rgb(244, 247, 251);

  input {
    height: 43px;
    color: #152935 !important;
    padding: 12px !important;
    font-size: 14px !important;

    &:read-only {
      color: gray !important;

      border: none !important;

      &:hover {
        cursor: default;
      }
    }
  }

}

ion-textarea {
  --padding-start: 0;
  --background: #f4f7fb !important;
  margin-top: 9px !important;
  border-bottom: #000 solid 1px;
  font-size: 14px !important;
}

.select {
  --padding-start: 0;
  margin-top: 9px !important;
  padding: 12px;
  height: 44px;
  background-color: #f4f7fb !important;
  border-bottom: #000 solid 1px;
}

.ng-invalid.ng-touched {
  border-bottom: red 1px solid;
}

form.ng-invalid.ng-touched, ion-row.ng-invalid.ng-touched {
  border-bottom: none !important;
}

ion-label.ion-invalid {
  color: red;
}
